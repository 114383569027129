import React from "react";
import {Routes, Route } from 'react-router-dom';
import Login from "./Admin/Pages/Login/indexs";
import PrivateAdminRoute from "./Privates/Privates";
import Main from "./Admin/main";
import OmborMain from "./OmborchiAdmin/pages/main";




function App() {

  return (
    <div>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<PrivateAdminRoute />}>
          <Route path='/main/*' element={<Main />} />
        </Route>
        <Route path='/ombor/main/*' element={<OmborMain />} />
      </Routes>
    </div>
  );
}

export default App;
