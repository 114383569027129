
import React, { useEffect, useState } from "react";
import logo from "../../../assets/logoadu.png";
import { Button, Modal, Table, Form, message, Breadcrumb, Layout } from 'antd';
import { PlusCircleOutlined, EditOutlined, HomeOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  DesktopOutlined,
  BankOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ProfileOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AddProductName from "./AddProductName";
import axiosConfig from "../../../BaseUrl";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const ProductName = () => {
 
  const [open, setOpen] = useState(false)
  const [allProduct, setAllProduct] = useState()

  const getName = () =>{
    axiosConfig.get('/kirituvchi/product-name').then(res=>{
        console.log(res.data.productNames);
        setAllProduct(res.data.productNames)
    }).catch(err=>{
        console.log(err);
    })
  }

  useEffect(()=>{
    getName()
  },[open])

  const columns = [
    {
        title: '№',
        width: '5%',
        render: (text, row, index) => (
            <>
                {index + 1}
            </>
        ),
    },
    {
        title: 'Mahsulotlar nomlari',
        dataIndex:"name",
        key:"name",
        width: '15%',
    },
   

    // {
    //     title: 'Holati',
    //     dataIndex: 'active',
    //     key: 'active',
    //     width: '20%',
    //     render: (text, row) => (
    //         <div>
    //             {row.active == "1" ?
    //                 <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-secondary">Active</div>
    //                 :
    //                 <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-danger">Passive</div>}
    //         </div>
    //     ),
    // },
    {
        title: 'Amallar',
        width: '20%',
        render: (text, row) => (
            <div>
                <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
                <PlusCircleOutlined style={{ fontSize: "20px", marginLeft: "25px", cursor: "pointer" }} />
                {/* <DeleteOutlined style={{ fontSize: "20px", marginLeft: "20px", cursor: "pointer", color: "red" }} onClick={() => deleteEtaj(row.id)} /> */}
            </div>
        ),
    },
];


  return (
    <div>
      <div style={{width:"100%", display:"flex", justifyContent:"end"}}>
      <Button onClick={() => setOpen(true)} type="primary">Qo'shish</Button>
      </div>
      <hr/>
      <Table columns={columns} dataSource={allProduct}  pagination={false} />

      <AddProductName open={open} setOpen={setOpen}/>
    </div>
  );
};

export default ProductName;
