import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Form, Breadcrumb, message, } from 'antd';
import axiosConfig from "../../../BaseUrl";

const AddProductName = ({ open, setOpen }) => {

    // const [lotRaqami, setLotRaqami] = useState()
    // const [shartnomaRaqami, setShartnomaRaqami] = useState()
    // const [shartnomaSanasi, setShartnomaSanasi] = useState()
    // const [yetkazibBeruvchi, setYetkazibBeruvchi] = useState()
    // const [mahsulotNomi, setMahsulotNomi] = useState()
    // const [ulchovBirligi, setUlchovBirligi] = useState()
    // const [soni, setsoni] = useState()
    // const [narxi, setNarxi] = useState()
    // const [summasi, setSummasi] = useState()

    const [allData, setAllData] = useState({
        name:"",
    })

    


    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAllData({
          ...allData,
          [name]: value
        });
      };

      const postName = () =>{
        axiosConfig.post('/kirituvchi/product-name', allData).then(res=>{
            console.log(res);
        }).catch(err=>{
            console.log(err);
        })
      }

    

    return (
        <Modal
        style={{width:"600px"}}
            open={open}
            title="Mahsulot nomini kiriting"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={() => (
                <div>
                    <div>
                        <div>
                        <Button  onClick={()=>{postName(); setOpen(false)}} type="primary">Qo'shish</Button>
                        </div>
                    </div>

                </div>
            )}
        >
            {/* <p>{JSON.stringify(allData)}</p> */}
            <div style={{ width: "100%", display: "flex", justifyContent:"space-between", margin: "0, 5%" }}>
                <div>
                    <div>
                        <label className="mt-2" htmlFor="">Mahsulot nomi</label>
                        <input onChange={handleChange} name="name" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                    </div>
                </div>
                
            </div>
        </Modal>
    )
}

export default AddProductName
