import React from "react";
import { Navigate, Outlet } from "react-router";

const PrivateAdminRoute = () => {
    const user = sessionStorage.getItem("token");
    // const role = sessionStorage.getItem("role")  && role == 0 
    return <div>{user !== ""? <Outlet /> : <Navigate to={"/"} />}</div>;
  };


  export default PrivateAdminRoute;