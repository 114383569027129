import React, { useState } from "react";
import { Button, Modal, Table, Form, Breadcrumb, message, } from 'antd';
import {
    PlusCircleTwoTone,
} from "@ant-design/icons";

const AddProductsContrakt = ({ open, setOpen }) => {

    // const [lotRaqami, setLotRaqami] = useState()
    // const [shartnomaRaqami, setShartnomaRaqami] = useState()
    // const [shartnomaSanasi, setShartnomaSanasi] = useState()
    // const [yetkazibBeruvchi, setYetkazibBeruvchi] = useState()
    // const [mahsulotNomi, setMahsulotNomi] = useState()
    // const [ulchovBirligi, setUlchovBirligi] = useState()
    // const [soni, setsoni] = useState()
    // const [narxi, setNarxi] = useState()
    // const [summasi, setSummasi] = useState()

    const [allProducts, setAllProducts] = useState([{
        mahsulotNomi: "",
        ulchovBirligi: "",
        soni: "",
        narxi: "",
        // summasi: "",
    }])

    const [allData, setAllData] = useState({
        lotRaqami: "",
        shartnomaRaqami: "",
        shartnomaSanasi: "",
        yetkazibBeruvchi: "",
        stir: "",
        products: [
            {
                mahsulotNomi: "",
                ulchovBirligi: "",
                soni: "",
                narxi: "",
                summasi: "",
            }
        ]
    })

    const addProduct = () => {
        setAllProducts([...allProducts, {
            mahsulotNomi: "",
            ulchovBirligi: "",
            soni: "",
            narxi: "",
            summasi: "",
        }])
    }


    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAllData({
            ...allData,
            [name]: value
        });
    };

    console.log(open);

    return (
        <Modal
            style={{ width: "600px" }}
            open={open}
            title="Sotib olingan mahsulotni kiritish"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={() => (
                <div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                        <div>
                            <PlusCircleTwoTone onClick={addProduct} />
                        </div>
                        <div>
                            <Button type="primary">Qo'shish</Button>
                        </div>

                    </div>

                </div>
            )}
        >
            {/* <p>{JSON.stringify(allData)}</p> */}
            <div style={{ width: "100%", justifyContent: "space-between", margin: "0, 5%" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "0, 5%" }}>
                    <div>
                        <div>
                            <label className="mt-2" htmlFor="">Lot raqami</label>
                            <input onChange={handleChange} name="lotRaqami" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                        </div>
                        <div>
                            <label className="mt-3" htmlFor="">Shartnoma raqami</label>
                            <input onChange={handleChange} name="shartnomaRaqami" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <label className="mt-2" htmlFor="">Shartnoma sanasi</label>
                            <input onChange={handleChange} name="shartnomaSanasi" style={{ boxShadow: "none" }} required className="form-control" type="date" />
                        </div>
                        <div>
                            <label className="mt-3" htmlFor="">Yetkazib beruvchi tashkilot STIR  </label>
                            <input onChange={handleChange} name="yetkazibBeruvchi" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                        </div>
                    </div>
                </div>
                <div>
                    <label className="mt-3" htmlFor="">Yetkazib beruvchi tashkilot nomi </label>
                    <input onChange={handleChange} name="stir" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                </div>

                <hr />

                {
                    allProducts?.map((item, index) => (
                        <div key={index}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "0, 5%" }}>
                                <div>
                                    <div>
                                        <label className="mt-2" htmlFor="">Mahsulot nomi</label>
                                        <input onChange={handleChange} name="mahsulotNomi" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                                    </div>
                                    <div>
                                        <label className="mt-2" htmlFor="">O'lchov birligi</label>
                                        <input onChange={handleChange} name="ulchovBirligi" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label className="mt-2" htmlFor="">Soni</label>
                                        <input onChange={handleChange} name="soni" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                                    </div>
                                    <div>
                                        <label className="mt-2" htmlFor="">Narxi</label>
                                        <input onChange={handleChange} name="narxi" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <label className="mt-2" htmlFor="">Summasi</label>
                                <input onChange={handleChange} name="summasi" style={{ boxShadow: "none" }} required className="form-control" type="text" />
                            </div> */}
                            <hr />
                        </div>
                    ))
                }
            </div>
        </Modal>
    )
}

export default AddProductsContrakt
