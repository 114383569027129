
import React, { useState } from "react";
import logo from "../assets/logoadu.png";
import "./main.css";
import {
    ProductOutlined,
    BankOutlined,
    UserOutlined,
    UsergroupAddOutlined,
    ProfileOutlined,
    LogoutOutlined,
    AppstoreAddOutlined
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import PurchasedProducts from "./Pages/AddContraktPage";
import ProductName from "./Pages/ProductName";
import OmborProduct from "../OmborchiAdmin/pages/OmborProducts/OmborProduct";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const Main = () => {
    const navigate = useNavigate();
    const items = [
        getItem(
            <div onClick={() => navigate("/main")}>
                Mahsulotlar
            </div>,
            "1",
            <ProductOutlined onClick={() => navigate("/main/products")} />
        ),
        getItem(
            <div onClick={() => navigate("/main/products/name")}>
                Mahsulotlar ro'yxati
            </div>,
            "2",
            <AppstoreAddOutlined onClick={() => navigate("/main/products")} />
        ),
        getItem(
            <div onClick={() => navigate("/main/ombor")}>
                Omborchi
            </div>,
            "3",
            <AppstoreAddOutlined onClick={() => navigate("/main/products")} />
        ),
        // getItem('User', 'sub1', <UserOutlined />, [
        //     getItem('Tom', '3'),
        //     getItem('Bill', '4'),
        //     getItem('Alex', '5'),
        // ]),
        // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
        // getItem("Files", "9", <FileOutlined />),
    ];
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const logOut = () => {
        sessionStorage.clear()
        navigate('/')
    }

    return (
        <div>
            <Layout style={{ minHeight: "100vh" }}>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                >
                    <div className="demo-logo mt-3"></div>
                    <div className="demo-logo-vertical demo-logo">
                        <img className="img-fluid" src={logo} alt="" />
                    </div>
                    <Menu
                        style={{ padding: "30px 0 0 0" }}
                        theme="dark"
                        defaultSelectedKeys={["1"]}
                        mode="inline"
                        items={items}
                    />
                </Sider>
                <Layout>
                    {/* <Header style={{ padding: 10, background: colorBgContainer, }} /> */}
                    <Content style={{ margin: "0 16px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Breadcrumb style={{ margin: "16px 0" }}>
                                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {sessionStorage.getItem("name")}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            {/* <div>
                                <button style={{ height: "30px", display: "flex", alignItems: "center" }} onClick={logOut} className="btn btn-danger"><LogoutOutlined /> Log out</button>
                            </div> */}
                        </div>

                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            <Routes>
                                <Route path="/" element={<PurchasedProducts />} />
                                <Route path="/ombor" element={<OmborProduct />} />
                                <Route path="/products/name" element={<ProductName />} />
                            </Routes>
                        </div>
                    </Content>

                    <Footer
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Omborxona ADU {new Date().getFullYear()} Created by <b>RTTRM</b>
                    </Footer>
                </Layout>
            </Layout>
        </div>
    );
};

export default Main;
