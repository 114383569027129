
import React, { useState } from "react";
import logo from "../../../assets/logoadu.png";
import { Button, Modal, Table, Form, message, Breadcrumb, Layout } from 'antd';
import "./addContraktPage.css";
import { PlusCircleOutlined, EditOutlined, HomeOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  DesktopOutlined,
  BankOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ProfileOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AddProductsContrakt from "./AddProducts";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const PurchasedProducts = () => {
 
  const [open, setOpen] = useState(false)

  const columns = [
    {
        title: '№',
        width: '5%',
        render: (text, row, index) => (
            <>
                {index + 1}
            </>
        ),
    },
    {
        title: 'Lot raqami',
        render: (text, row) => (
            <>
                {row.accommodation.name}
            </>
        ),
        width: '15%',
    },
    {
        title: 'Shartnoma raqami',
        render: (text, row) => (
            <>
                {row.block.name}
            </>
        ),
        width: '15%',
    },
    {
        title: 'Shartnoma sanasi',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
    },
    {
      title: 'Yetkazib beruvchi tashkilot nomi ',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
  },
    {
      title: 'Yetkazib beruvchi tashkilot STIR ',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
  },
//   {
//     title: 'Mahsulot nomi',
//     dataIndex: 'name',
//     key: 'name',
//     width: '15%',
// },
// {
//   title: `O'lchov birligi`,
//   dataIndex: 'name',
//   key: 'name',
//   width: '10%',
// },
// {
//   title: `Soni`,
//   dataIndex: 'name',
//   key: 'name',
//   width: '5%',
// },
// {
//   title: `Narxi`,
//   dataIndex: 'name',
//   key: 'name',
//   width: '10%',
// },
{
  title: `Summasi`,
  dataIndex: 'name',
  key: 'name',
  width: '10%',
},
    // {
    //     title: 'Holati',
    //     dataIndex: 'active',
    //     key: 'active',
    //     width: '20%',
    //     render: (text, row) => (
    //         <div>
    //             {row.active == "1" ?
    //                 <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-secondary">Active</div>
    //                 :
    //                 <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-danger">Passive</div>}
    //         </div>
    //     ),
    // },
    {
        title: 'Amallar',
        width: '20%',
        render: (text, row) => (
            <div>
                <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
                <PlusCircleOutlined style={{ fontSize: "20px", marginLeft: "25px", cursor: "pointer" }} />
                {/* <DeleteOutlined style={{ fontSize: "20px", marginLeft: "20px", cursor: "pointer", color: "red" }} onClick={() => deleteEtaj(row.id)} /> */}
            </div>
        ),
    },
];


  return (
    <div>
      <div style={{width:"100%", display:"flex", justifyContent:"end"}}>
        <Button onClick={() => setOpen(true)} type="primary">Qo'shish</Button>
      </div>
      <hr/>
      <Table columns={columns}  pagination={false} />

      <AddProductsContrakt open={open} setOpen={setOpen}/>
    </div>
  );
};

export default PurchasedProducts;
